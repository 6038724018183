import React, { useState, useRef } from "react"
import ReactMarkdown from "react-markdown"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import SoundwaveNumber from "./soundwave-number"
import Years from "../images/years.inline.svg"
import WeChoose2 from "../images/we-choose-2-logo.inline.svg"
import IconClose from "../images/icon-close.inline.svg"
import DoubleArrow from "../images/double-arrow.inline.svg"
import "./_timeline.scss"

const TimelineItem = ({ title, copy, image }) => {
  return (
    <div className="timeline-item">
      {image &&
      <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.alternativeText} />
      }
      <div className="timeline-item-mask">
        <div className="timeline-item-title">{title}</div>
        {copy && copy.data && copy.data.copy &&
        <div className="timeline-item-copy"><ReactMarkdown children={copy.data.copy} /></div>
        }
      </div>
    </div>
  )
}
TimelineItem.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired,
  image: PropTypes.object
}


// ---------- ---------- ----------


function PrevArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <DoubleArrow />
      <span>Scroll</span>
    </div>
  )
}
function NextArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <span>Scroll</span>
      <DoubleArrow />
    </div>
  )
}

const Timeline = ({ flavor1, flavor2, timeline, description, title, callback, i }) => {
  const [transpos, setTranspos] = useState(null)
  const [leftpos, setLeftpos] = useState(null)
  // const [footerClasses, setFooterClasses] = useState(['timeline-footer'])
  const footerRef = useRef(null)
  const sliderRef = useRef(null)
  // const hideFooterText = true
  // const hideFooterSvg = false
  // let timelineClasses = ['timeline']

  const settings = {
    // dots: true,
    // adaptiveHeight: true,
    // centerMode: true,
    // centerPadding: '0',
    // centerPadding: '40px',
    // fade: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // swipeToSlide: true,
    touchThreshold: 10,
    dots: false,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    speed: 600,
    beforeChange: (prevIdx, nextIdx) => {
      // console.log(nextIdx, timeline.length + 1)
      const isLarge = window.matchMedia('(min-width: 992px)').matches
      console.log(prevIdx, nextIdx, (Math.round(nextIdx)+1)/timeline.length)
      const idx = prevIdx > nextIdx ? Math.round(nextIdx) : Math.round(nextIdx + 1)
      console.log(idx/timeline.length*100)
      // console.log(nextIdx, timeline.length, Math.round(nextIdx/timeline.length*100))
      if (isLarge) {
        setTranspos(50)
        setLeftpos(100 - Math.round(idx/timeline.length*100))
      } else {
        setTranspos(Math.round(nextIdx/timeline.length*100))
        setLeftpos(80 - Math.round(nextIdx/timeline.length*60))
      }

      // console.log(nextIdx, timeline.length, Math.round(nextIdx) + 1)
      if (nextIdx <= 0) {
        // console.log('REMOVE: hide-text')
        footerRef.current.classList.remove('hide-text')
        // footerClasses = setFooterClasses(footerClasses.)
      } else {
        // console.log('ADD: hide-text')
        footerRef.current.classList.add('hide-text')
        // footerClasses = setFooterClasses(footerClasses.push('hide-text'))
      }
      if (timeline.length === Math.round(nextIdx) + 1) {
        // console.log('ADD: show-years')
        footerRef.current.classList.add('show-years')
        // footerClasses = setFooterClasses(footerClasses.push('show-years'))
      } else {
        // console.log('REMOVE: show-years')
        footerRef.current.classList.remove('show-years')
      }
    },
    // afterChange: idx => {
    //   console.log(idx)
    //   setTranspos(Math.round(idx/timeline.length*100))
    //   setLeftpos(80 - Math.round(idx/timeline.length*60))
    // }
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
          // swipeToSlide: true,
          // nextArrow: <NextArrow />,
          // prevArrow: <PrevArrow />,      
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          // swipeToSlide: false,
        }
      },
    ]
  }

  const callbackAndReset = () => {
    callback(false)
    setTimeout(() => {
      sliderRef.current.slickGoTo(0, true)
    }, 600)
  }

  return (
    <div className="timeline">
      <div className="timeline-header">
        <WeChoose2 />
        <button onClick={() => callbackAndReset()}>
          <IconClose />
        </button>
      </div>
      <div className="flavor-wrap">
        <div className="flavor1">{flavor1}</div>
        <div className="flavor2">{flavor2}</div>
      </div>
      <div className="timeline-carousel">
        <Slider {...settings} ref={sliderRef}>
          <div className="timeline-item">
            <div className="timeline-item-copy">
              <ReactMarkdown children={description} />
            </div>
          </div>
          {timeline.map(item => <TimelineItem {...item} key={item.id} /> )}
        </Slider>
        <SoundwaveNumber i={i} style={{left: `${leftpos}%`, transform: `translate(-${transpos}%, -50%`}} />
      </div>
      <div className="timeline-footer" ref={footerRef}>
        <div className="footer-text">{title}</div>
        <div className="footer-text">WE CHOOSE 2</div>
        <Years className="footer-years" />
      </div>
    </div>
  )
}

Timeline.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  flavor1: PropTypes.string.isRequired,
  flavor2: PropTypes.string.isRequired,
  timeline: PropTypes.array
}

export default Timeline
