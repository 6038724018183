import React, { useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Timeline from "./timeline"
import IconNumber from "./icon-number"
import SoundwaveNumber from "./soundwave-number"
import CircleIcon from "../images/icon-circle-arrow.inline.svg"
import AtomIcon from "../images/icon-atom.inline.svg"
import "./_history.scss"

const History = data => {
  const [isOpen, setIsOpen] = useState(false)
  const img1 = data.image[0]
  const img2 = data.image[1]
  const img3 = data.image[2]
  // console.log(data.i, data.i % 2)

  let classes = ['history']
  if (typeof document !== 'undefined') {
    if (isOpen) {
      classes.push('open')
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }

  const slugifyTitle = title => title.toLowerCase().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-')

  return (
    <div id={slugifyTitle(data.title)} className={classes.join(' ')}>
      <div className="history-wrap">
        <div className="history-grid">
          {img1 &&
            <GatsbyImage objectFit={"contain"} className="image1" image={img1.localFile.childImageSharp.gatsbyImageData} alt={img1.alternativeText} />
          }
          {img2 &&
            <GatsbyImage objectFit={"contain"} className="image2" image={img2.localFile.childImageSharp.gatsbyImageData} alt={img2.alternativeText} />
          }
          <div className="title-cta">
            <h2><em>{data.title}</em></h2>
            <button onClick={() => setIsOpen(!isOpen)}>
              Learn More <CircleIcon />
            </button>
          </div>
        </div>
        {img3 &&
          <GatsbyImage objectFit={"contain"} className="image3" image={img3.localFile.childImageSharp.gatsbyImageData} alt={img3.alternativeText} />
        }
        <IconNumber i={data.i} />
        <AtomIcon alt="atom" className="icon-atom" />
      </div>
      <div className="soundwave">
        <SoundwaveNumber i={data.i} />
      </div>

      {/* Effectively the footer */}
      <div className="flavor-wrap">
        <div className="flavor1">{data.flavor1}</div>
        <div className="flavor2">{data.flavor2}</div>
      </div>

      {/* The modal */}
      {data.timeline &&
        <Timeline {...data} callback={setIsOpen} />
      }
    </div>
  )
}
History.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  flavor1: PropTypes.string.isRequired,
  flavor2: PropTypes.string.isRequired,
  image: PropTypes.array.isRequired,
  timeline: PropTypes.array
}

export default History
