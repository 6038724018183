import { graphql } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Subheader from "../components/subheader"
import History from "../components/history"
import Chooser from "../components/chooser"

// markup
const ActPage = ({ data }) => {
  // const gotoId = (id) => {
  //   const el = document.getElementById(id)
  //   // console.log(id, el)
  //   window.scrollTo({
  //     top: el.offsetTop,
  //     behavior:"smooth"
  //   })
  // }

  return (
    <Layout id="act">
      <Helmet>
        <title>Impact | JFK We Choose 2</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-D3S544GNMX"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-D3S544GNMX');
        `}</script>
      </Helmet>
      <Hero {...data.strapiAct.hero} />
      <Subheader {...data.strapiAct.act} />
      <Chooser />
      <div className="history-wrap">
        {data.strapiAct.history.map((history, i) => <History {...history} i={i} key={history.id} /> )}
      </div>
    </Layout>
  )
}

export default ActPage

export const query = graphql`
{
  strapiAct {
    hero {
      title
      description
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    act {
      title
      subtitle
      copy
    }
    history {
      id
      title
      description
      flavor1
      flavor2
      image {
        id
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      timeline {
        id
        title
        copy {
          data {
            copy
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}`;
