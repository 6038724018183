import React from "react"
import { useInView } from 'react-intersection-observer'
import IconGlobeAltAlt from "../images/icon-globe-alt-alt.inline.svg"
import "./_chooser.scss"

const Chooser = () => {
  const { ref, inView } = useInView({
    rootMargin: '200px 0px -80px',
    threshold: 0.5,
    // triggerOnce: true,
  })

  return (
    <div className={`chooser ${inView ? 'in-view active' : 'in-view'}`} ref={ref}>
      <div>
        <IconGlobeAltAlt />
        <p>Choose a topic to explore the impact.</p>
      </div>
    </div>
  )
}

export default Chooser
